angular
    .module('annexaApp')
    .component('annexaBoxObjectThirdsTask',{
    	templateUrl: './components/thirds/annexa-box-object-third-task/annexa-box-object-third-task.html',
    	controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals',  'DialogsFactory', 'TaskFactory', 'ThirdFactory',
    		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, DialogsFactory, TaskFactory, ThirdFactory) {
    			//Està basat en annexaBoxObjectDossierTask
    			var vm = this;
    			vm.languageColumn = undefined;
    			vm.taskBox = undefined;
    			vm.loggedUser = $rootScope.LoggedUser;
    			vm.delete = function(taskId){
    				var titleDeleteMessage = '';
    	        	var confirmDeleteMessage = '';
    	        	
    	        	titleDeleteMessage = 'global.literals.delete';
    	        	confirmDeleteMessage = 'global.literals.deleteTaskBox';
    	        	
    	        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
    	                .then(function (data) {
    	                	ThirdFactory.deleteRelatedTask(vm.third.id, taskId).then(function(data){
								if(vm.taskTableDefinition && vm.taskTableDefinition.reloadInternalData) {
									vm.taskTableDefinition.reloadInternalData(true, true);	
								}
//								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
							}).catch(function (error) {
//								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
//	        					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		                    });
    	                    
    	                }).catch(function (data) {
    	                    //Empty
    	            });
    			};
    			
    			var addTaskSave = function(newTask) {
                    if(newTask && newTask.length > 0){
                    	ThirdFactory.createRelatedTask(vm.third.id, CommonService.getParameterList($linq(newTask).select("x => x.id").toArray())).then(function (data) {
    						if(vm.taskTableDefinition && vm.taskTableDefinition.reloadInternalData){
    							vm.taskTableDefinition.reloadInternalData(true, true);	
    						}
                        }).catch(function (error) {
//                        	vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
//            				vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
                        });
                    }
                };
    			
    			vm.generateTaskBox = function(){
    				if(!vm.taskTableDefinition){
    					vm.taskTableDefinition = {};
    				}
    				var taskStatusRender = function(data, type, full, meta) {
	    	            var content = '';
	    				if(TaskFactory.taskStatuses && TaskFactory.taskStatuses.length > 0){
	    					var status = $linq(TaskFactory.taskStatuses).singleOrDefault(undefined, "x => x.id == '" + data.globalStatus + "'");
	                        if(status) {
	    	                    content += '<div class="text-center">';
	    						content += '<span class="fa-stack" title="'+((data && data[vm.languageColumn])?data[vm.languageColumn]:'')+'">';
	    						content += '<i class="fa fa-' + status.icon + ' ' + status.style + ' fa-lg fa-stack-1x"></i>';
	    						content += '</span>';
	    		            	content += '</div>';
	    					}
	    				}
	    	            return content;
	    	        }

	    			var taskDaysRender = function(data, type, full, meta) {
	    				var getMaxDays = function(task){
	    	                var holidays = [];
	    	                var workinDays = 0;
	    	
	    	                if(vm.loggedUser && vm.loggedUser.entity && vm.loggedUser.entity.holidays){
	    	                    holidays = vm.loggedUser.entity.holidays;
	    	                }
	    	
	    	                workinDays = HelperService.getWorkingDays(new Date(task.createdDate), new Date(), holidays);
	    	
	    	                return task.days - workinDays;
	    	            }
	    	            var getClass = function(task) {
	    	                return HelperService.getWorkingDaysClass(getMaxDays(task));
	    	            }
	    	            var getLabel = function(task) {
	    	                var maxDays = getMaxDays(task);
	    	
	    	                if(maxDays > 5) {
	    	                    return '+' + maxDays;
	    	                } else {
	    	                    return maxDays;
	    	                }
	    	            }
	    	            var taskEnd = false;
	    	            var content = '';
	    	            if(full.task.endDate){
	    	                taskEnd = true;
	    	            }
	    	            if(!taskEnd) {
	    	            	content += '<span class="label '+getClass(full.task)+'">';
	    	            	content += getLabel(full.task);
	    	            	content += '</span>';
	    	            }
	    	            return content;
	    	        }

	    			var actionsTitle = function () {
	                    return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
	                }

	                var actionsRender = function (data, type, full, meta) {
	    				var content = '';
	    				if(AnnexaPermissionsFactory.haveSinglePermissionDual('edit_third', undefined)){
	    					content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.delete('+full.task.id+')" permission permission-only="[\'edit_third\']">';
	    	                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
	    	                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
	    	                content += '</button>';
	    				}
	                    return content;
	                }
	                
	                var taskNumberRender = function (data, type, full, meta) {
	    				var content = '';
	    				if(data && full && full.id){
	    					content += '<a class="hellip text-primary" ui-sref="annexa.tasks.assignedTasks.edit({ task:' + full.task.id + '})" target="_blank" rel="noopener">'+ data + '</a>';
	    				}
	                    return content;
	                }
	                var columns = [
    					{ id: 'task.status', width: '53px', title: $filter('translate')('global.literals.state'), render: taskStatusRender},
    					{ id: 'task.endedTask', width: '53px', title: $filter('translate')('global.literals.days'), render: taskDaysRender},
    					{ id: 'task.taskNumber', width: '250px', title: $filter('translate')('global.literals.code'), render: taskNumberRender},
                        { id: 'task.description', width: '250px', column: new StrippedColumn($filter, 'global.literals.task_name',200)},
                        { id: 'task.createdUser', width: '250px', column:new UserColumn($filter, 'global.literals.created_for', false, true)},
    					{ id: 'task.user', width: '250px', column: new UserOrProfileColumn($filter, 'global.literals.assigned_to', 'profile', vm.languageColumn, true)},
    					{ id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
    		        ];
    				var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
    		        vm.taskTableDefinition.id = 'tableDTTasks';
    				vm.taskTableDefinition.origin = 'tasks';
    		        vm.taskTableDefinition.objectType = 'TaskThird';
    				vm.taskTableDefinition.objectIdToSelectOrigin ='tasks';
    				vm.taskTableDefinition.objectIdToSelectType = 'TaskThird';
    		        vm.taskTableDefinition.sortName = tableOrderProperties.sortName;
    		        vm.taskTableDefinition.sort = tableOrderProperties.sort;
    				vm.taskTableDefinition.columns = columns;
    		        vm.taskTableDefinition.containerScope = $scope;
    				vm.taskTableDefinition.useTableObjects = true;
//    				vm.taskTableDefinition.callOrigin = 'thirds';
    				vm.taskTableDefinition.objectIdToSelect = angular.fromJson({third:{id:vm.third.id}});
    				vm.taskTableDefinition.objectIdToAdded = vm.third.id;
    			
    			};
    			this.$onInit = function() {
    				vm.languageColumn = Language.getActiveColumn();
    				vm.legend = undefined;
    				vm.isUpdate = AnnexaPermissionsFactory.haveSinglePermissionDual('edit_third', undefined);
    				vm.taskBox = {};
    	    		try{
    					vm.generateTaskBox();
    					vm.taskBox = {
    							boxTitle: 'global.tasks.literals.relatedTasks',
    	                        permissions: { new: ['create_task'], view: ['create_task'] },
    	                        search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: ((vm.isUpdate) ? addTaskSave : undefined)},
    	    					tableDefinition: vm.taskTableDefinition,
    	                        new: {},
    	                        isEdit: true,
    	                        config: {third: vm.third},
    	                        origin: 'third-task',
    	    					alerts: undefined,//vm.dossierTransactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossierAlerts,
    	    					legend: vm.legend,
    	    					closeBox: {value:true},
    		    				numberBox: undefined
    	                    }
    					$scope.$on('annexaBoxThirdTaskTable', function(event, args) {
    			            if(vm.taskTableDefinition && vm.taskTableDefinition.reloadInternalData) {
    							vm.taskTableDefinition.taskInternalData(true, true);	
    						}
    						//vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
    			        });
    				}catch(error){
    					 console.error(error);
    				}
    			}
    	}],
      bindings: {
    	  third:'='
      }
    });