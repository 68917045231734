angular
    .module('annexaApp')
    .component('annexaBoxObjectThirdsRegisterentry',{
    	templateUrl: './components/thirds/annexa-box-object-thirds-registerentry/annexa-box-object-thirds-registerentry.html',
    	controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'NotificationFactory', 'AnnexaFormlyFactory', 'TerritoryFactory', 'SecFactory', 'AnnexaPermissionsFactory', '$state', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 'RegFactory', 
    		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, NotificationFactory, AnnexaFormlyFactory, TerritoryFactory, SecFactory, AnnexaPermissionsFactory, $state, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http, RegFactory) {
    			//Està basat en annexaBoxObjectDossierRegisterOutputs
    			var vm = this;
    			vm.languageColumn = undefined;
    			vm.registerEntryBox = undefined;
    			vm.deleteRegEntry = function(regId){
    				var titleDeleteMessage = '';
    	        	var confirmDeleteMessage = '';
    	        	
    	        	titleDeleteMessage = 'global.literals.delete';
    	        	confirmDeleteMessage = 'global.literals.deleteRegisterEntryBox';
    	        	
    	        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
    	                .then(function (data) {
    	                	RegFactory.deleteRegisterEntryThirdWithThird(regId, [vm.third.id]).then(function(data){
								if(vm.regTableDefinition && vm.regTableDefinition.reloadInternalData) {
									vm.regTableDefinition.reloadInternalData(true, true);	
								}
//								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
							}).catch(function (error) {
//								vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.length = 0;
//	        					vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.dossierAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		                    });
    	                    
    	                }).catch(function (data) {
    	                    //Empty
    	            });
    			}
    			var addRegisterEntrySave = function(reg){
    				var getRepresentateds = function(data) {
                        var retRepresentateds = [];
                       	var representeds = $linq(data).where("x => x.representated != null").select("x => x.representated").toArray();
                       	var interested = $linq(data).where("x => x.interested").except(representeds,"(x,y) => (x.id && (x.id == y.third.id)) || (x == y.third.id)").toArray();
                       	_.forEach(interested, function (value) {
                            var representated = value.third;
                            if(value.third.completeName){
                            	representated.label = value.third.completeName + ' (' + value.third.identificationDocument + ')';
                        	}else if(value.third.name) {
                                representated.label = value.third.name + ((value.third.surname1Particles)? ' ' + value.third.surname1Particles : '') + ' ' + value.third.surename1 + ((value.third.surname2Particles)? ' ' + value.third.surname2Particles : '') + ((value.third.surename2)? ' ' + value.third.surename2 : '') + ' (' + value.third.identificationDocument + ')';
                            }else{
                                representated.label = value.third.corporateName + ' (' + value.third.identificationDocument + ')';
                            }
                            retRepresentateds.push(representated);
                        });

                        return retRepresentateds
                    }
                	
                	var addThirdToReg = function(){
                		var self = this;
                    	var thirdAddressId;
                    	var thirdToAdd = angular.copy(vm.third);
                    	if (this.model && this.model.thirdAddress) {
                    		thirdAddressId = this.model.thirdAddress
                    	}
                		var newContent = {
                            third: {id:thirdToAdd.id},
                            interested: (( this.model.thirdType == 'interested')?true:false),
                            representative:  (( this.model.thirdType == 'representative')?true:false),
                            principal: false,
                            representated: ((this.model.representated)?{id:this.model.representated}:undefined),
                            roleInterested: ((this.model.roleInterested)?{id:this.model.roleInterested}:undefined),
                            registerEntry: {id: reg[0].id},
                            thirdAddress: ((thirdAddressId)?{id: thirdAddressId}:undefined)
                		};
                		RegFactory.newRegisterEntryThird(reg[0].id, newContent).then(function(data){
                        	if(vm.regTableDefinition && vm.regTableDefinition.reloadInternalData) {
        						vm.regTableDefinition.reloadInternalData(true, true);	
        					}
                        	$rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });
                		}).catch(function(error){
                			DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                		});
                	}
                	RegFactory.getRelatedThirds(reg[0].id).then(function(data){ 
                		var addressesArray = function() {1
                            var content = [];

                            if(vm.third.addresses) {
                            	var th = angular.copy(vm.third)
                            	var lstAddresses = th.addresses;
                            	var thirdAddress = new ThirdAddress(th, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
                            	
                            	if (lstAddresses && lstAddresses.length > 0) {
                            		var addressWithConsentOrDefault = ThirdFactory.getThirdAddresByThirdWithConsent(th, undefined, true);
									var notificationTypes = angular.copy(GlobalDataFactory.notificationTypes);
									notificationTypes = ((notificationTypes)?$linq(notificationTypes).select("x => x.addressType").distinct().toArray():[]);
									_.forEach(lstAddresses, function (value) {
										if(_.contains(notificationTypes, value.addressType)){
                                    		var thirdAddressId = value.id;
                                    		var addressHtml = thirdAddress.getAddressHtml(value, false, true);
                                    		var active = false;
                                    		var activeType = '';
                                    		if(addressWithConsentOrDefault && addressWithConsentOrDefault.address && addressWithConsentOrDefault.address.id && value.id && value.id == addressWithConsentOrDefault.address.id){
                                    			active = true;
                                    			if(addressWithConsentOrDefault.isDefault){
                                    				activeType = $filter('translate')('global.literals.defaultValue');
                                    			}else{
                                    				activeType = $filter('translate')('global.literals.withConsentAddress');
                                    			}
                                    		}
                                    		var address = {value: thirdAddressId, label: addressHtml, active:active, activeType: activeType};  
                                    		content.push(address);
										}
                                	});
                            	}
                            	
                            }
                            return content;
                        }
                		
                    	var modal = {
                            title: 'global.literals.edit_receiver',
                            size: '',
                            fieldsDefinition: [
                            	{ type: 'field', id: 'thirdType', fieldType: 'radio_checkbox', isRadio: true, 
                            		data: [{value: 'interested', label: $filter('translate')('global.literals.interested')},
                            			   {value: 'representative', label: $filter('translate')('global.literals.representative')}],
                            		defaultValue: 'interested',	   
                            		colClass: 'col-sm-12', required: true, label: 'global.literals.type' , labelProp: 'label'},
                        		{ type: 'field', id: 'roleInterested', fieldType: 'select', data: GlobalDataFactory.roleInteresteds, required: false, label: 'global.literals.roleInterested', labelProp: 'label',
                            		defaultValue: undefined,
                            		hideExpression: function($viewValue, $modelValue, scope) {
                            			var hide = true;
                                    	if (scope.model.thirdType == 'interested' && GlobalDataFactory.roleInteresteds) {
                                    		hide = false;
                                    	}
                                    	return hide;
                            		},	
                            		controller: ['$scope', function($scope) { 
                            			$scope.to.labelProp = Language.getActiveColumn();
                                    	$scope.to.options = GlobalDataFactory.roleInteresteds;
                                    }]
                            	},	
                                { type: 'field', id: 'representated', fieldType: 'select', data: getRepresentateds(data), required: true, label: 'global.literals.representated', labelProp: 'completeName',
                            		defaultValue: undefined,	
                                    hideExpression: function($viewValue, $modelValue, scope) { return scope.model.thirdType != 'representative'; },
                                    controller: ['$scope', function($scope) { 
                                    	$scope.to.options = getRepresentateds(data)
                                    }]
                                },
                                { type: 'field', id: 'thirdAddress', fieldType: 'radio_checkbox', isRadio: true, data: addressesArray(), colClass: 'col-sm-12', required: false, label: 'global.literals.notificationChannel' , labelProp: 'label',
                                	defaultValue: undefined,
                                    controller: ['$scope', function($scope) { 
                                    	$scope.to.options = addressesArray()
                                    }]},
                            ],
                            alerts: []
                        };     
                        modal.size = 'modal-md';
                        AnnexaFormlyFactory.showAnnexaFormModal('modalSearchAdd', modal, addThirdToReg);
                	}).catch(function(error){
                		DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                	});
    			};
    			var generateRegisterEntryBox= function(){
	    			if(!vm.regTableDefinition){
	    				vm.regTableDefinition = {};
	    			}
	    			var entryTypeRender = function(data, type, full, meta) {
	    				var content = '';
	    				if(data && data == 'INPUT'){
	    					content += '<i class="fa fa-sign-in" title="{{ \'global.literals.input\' | translate }}"></i>';
	    	            }else{
	    					content += '<span class="fa-transX-3px">';
	    	                content += '<i class="fa fa-sign-out fa-flip-horizontal" title="{{ \'global.literals.output\' | translate }}"></i>';
	    	            	content += '</span>';	
	    				}
	    				return content;
	    			}
	    			var actionsTitle = function () {
	    	            return '<div class="column-actions"></div>';
	    	        }
	                var actionsRender = function (data, type, full, meta) {
	    				var content = '';
	    				if(full.registerEntry && full.registerEntry.id){
	    					content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteRegEntry('+full.registerEntry.id+')" permission permission-only="[\'new_input_entry_register\',\'view_input_register\',\'view_all_input_register\',\'view_output_register\', \'view_all_output_register\']">';
	    	                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
	    	                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
	    	                content += '</button>';
	    				}
	                    return content;
	                }
	                
	                var entryNumberRender = function(data, type, full, meta) {
	    				var content = '';
	    				if(full && full.id && full.registerEntry && full.registerEntry.entryNumber) {
	    					content = '<annexa-href-permission-reg-input register-entry="tableObjects[' + full.id + '].registerEntry"></annexa-href-permission-reg-input>';
	    				}
	                    return content;
	    			}
	                
	    			var columns = [
	    				{ id: 'registerEntry.entryType', width: '53px', title: $filter('translate')('global.literals.type'), render: entryTypeRender},
	    				{ id: 'registerEntry.registerDate', width: '53px', column: new DateTimeColumn($filter, 'global.reg.datatable.registerDate')},
	    				{ id: 'registerEntry.entryNumber', width: '250px', title: $filter('translate')('global.literals.number'), render: entryNumberRender},
	                    { id: 'registerEntry.extract', width: '250px', column: new TextColumn($filter, 'global.literals.extract')},
	                    { id: 'registerEntry.registerEntryOffice', width: '250px', column:new DatabaseTranslatedParentColumn($filter, 'global.literals.office', vm.languageColumn)},
	    				{ id: 'registerEntry.principalThird', width: '250px', column: new ThirdColumn($filter, 'global.literals.principalThirdRegisterEntryInput',false), sortable: false},
	    				{ id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
	    	        ];
	    			
	    			var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
	    	        vm.regTableDefinition.id = 'tableTReg';
	    			vm.regTableDefinition.origin = 'reg';
	    	        vm.regTableDefinition.objectType = 'RegisterEntryThird';
	    			vm.regTableDefinition.objectIdToSelectOrigin ='reg';
	    			vm.regTableDefinition.objectIdToSelectType = 'RegisterEntryThird';
	    	        vm.regTableDefinition.sortName = tableOrderProperties.sortName;
	    	        vm.regTableDefinition.sort = tableOrderProperties.sort;
	    			vm.regTableDefinition.columns = columns;
	    	        vm.regTableDefinition.containerScope = $scope;
	    			vm.regTableDefinition.useTableObjects = true;
	    			vm.regTableDefinition.callOrigin = 'thirds';
	    			vm.regTableDefinition.objectIdToSelect = angular.fromJson({third:{id:vm.third.id}});
	    			vm.regTableDefinition.objectIdToAdded = vm.third.id;
	    			
	    			vm.registerEntryBox = {
	    			    boxTitle: 'global.literals.assentaments',
	    			    permissions: { view: ['view_input_register', 'diligence_input_register','view_all_diligences','view_all_input_register', 'view_output_register', 'view_all_output_register']},
	    				tableDefinition: vm.regTableDefinition,
	                    isEdit: true,
	                    search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch:addRegisterEntrySave},
	                    config: {third: vm.third},
	                    origin: 'thirds',
	    				alerts: {},
	    				searchByEntryType: false,
	    				legend: false,
	    				closeBox: {value:true},
	    				numberBox: undefined
	                }
	    		}
    			this.$onInit = function() {
    				vm.languageColumn = Language.getActiveColumn();
    				vm.registerEntryBox = {};
    	    		try{
    					generateRegisterEntryBox();	
    					$scope.$on('annexaBoxDossierRegisterReloadTable', function(event, args) {
    			            if(vm.regTableDefinition && vm.regTableDefinition.reloadInternalData) {
    							vm.regTableDefinition.reloadInternalData(true, true);	
    						}
    						//vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
    			        });
    				}catch(error){
    					 console.error(error);
    				}
    			}
    	}],
      bindings: {
    	  third:'='
      }
    });