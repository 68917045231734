/**
 * Created by osirvent on 16/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaThirdsAddRelatedDocuments', {
        templateUrl: './components/thirds/annexa-thirds-add-related-documents/annexa-thirds-add-related-documents.html',
        controller: ['RestService', 'Language', '$rootScope', '$filter', '$scope', 'DialogsFactory', function(RestService, Language, $rootScope,$filter, $scope, DialogsFactory) {
            var vm = this;
			this.$onInit = function() {
         	   	if(!vm.data){
         		   	vm.data = {
						thirds: [],
                        documents: [],
						relateds: {}
					};
         	   	}
				if(vm.data.thirds && vm.data.thirds.length > 0 && vm.data.relateds){
					var values = [];
					if(vm.data.relateds && vm.data.relateds.length > 0){
						_.forEach(vm.data.relateds, function(key){
							var third = $linq(vm.data.thirds).firstOrDefault(undefined, "x => x.third && x.third.id == "+key.thirdId);
							if(third && key.documents && key.documents.length > 0){
								_.forEach(key.documents, function(doc){
									var existInDoc = false;
									if(vm.data.documents && vm.data.documents.length != 0){
										var docAux = $linq(vm.data.documents).firstOrDefault(undefined, "x => x.document && x.document.id == "+doc.id);
										if(docAux){
											existInDoc = true;
										}
									}
									doc.selected = false;
									doc.existInDoc = existInDoc;
								});
								values.push({third: third, docs:angular.copy(key.documents), selected:false});
							}
	                    });
					}
					vm.data.values = values;
				}
			}
        }],
        bindings: {
        	data: '=?'
        }
    })